@import '~@assets/styles/variables';

.MuiDialogContent-root {
  padding: 20px !important;
}

.MuiBackdrop-root {
  background: rgba($blue-sodexo, 0.85) !important;
}

.MuiDialog-paper {
  border-radius: 20px !important;
}

@import '~@assets/styles/variables';

.MuiSnackbar-root {
  // [class*='SnackbarItem-variantSuccess'] {
  //   background-color: $success !important;
  //   color: $blue-sodexo;
  // }

  .snack {
    &--success {
      background: $success !important;
      color: $blue-sodexo !important;
    }

    &--warning {
      background: $warning !important;
    }

    &--error {
      background: $error !important;
    }

    &--info {
      background: $info !important;
    }
  }
}

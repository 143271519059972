@font-face {
  font-family: 'fs-icons';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/fs-icons/fs-icons.eot?20563742');
  src:
    url('../fonts/fs-icons/fs-icons.eot?20563742#iefix') format('embedded-opentype'),
    url('../fonts/fs-icons/fs-icons.woff2?20563742') format('woff2'),
    url('../fonts/fs-icons/fs-icons.woff?20563742') format('woff'),
    url('../fonts/fs-icons/fs-icons.ttf?20563742') format('truetype'),
    url('../fonts/fs-icons/fs-icons.svg?20563742#fs-icons') format('svg');
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fs-icons';
    src: url('../font/fs-icons.svg?20563742#fs-icons') format('svg');
  }
}
*/

[class^='icon-']::before,
[class*=' icon-']::before {
  display: inline-block;
  width: 1em;
  //margin-right: 0.2em;

  /* Animation center compensation - margins should be symmetric */

  /* remove if not needed */
  //margin-left: 0.2em;
  font-family: 'fs-icons';

  /* you can be more comfortable with increased icons size */

  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;

  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  font-weight: normal;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  speak: never;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;

  /* Uncomment for 3D effect */

  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

//FIXME : rebuild the carton icon
.icon-carton::before {
  content: '\e848';
  transform: scale(0.7);
  transform-origin: 0 center;
} /* '' */

.icon-excellent::before {
  content: '\e80e';
} /* '' */

.icon-close::before { content: '\e800'; } /* '' */
.icon-comment::before { content: '\e801'; } /* '' */
.icon-arrow_back::before { content: '\e802'; } /* '' */
.icon-time1::before { content: '\e803'; } /* '' */
.icon-time::before { content: '\e804'; } /* '' */
.icon-vegan::before { content: '\e805'; } /* '' */
.icon-timer::before { content: '\e806'; } /* '' */
.icon-visibility::before { content: '\e807'; } /* '' */
.icon-visibility_off::before { content: '\e808'; } /* '' */
.icon-couverts::before { content: '\e809'; } /* '' */
.icon-volaille::before { content: '\e80a'; } /* '' */
.icon-credit_card::before { content: '\e80b'; } /* '' */
.icon-crustace::before { content: '\e80c'; } /* '' */
.icon-handle::before { content: '\e80d'; } /* '' */
.icon-alerte::before { content: '\e828'; } /* '' */
.icon-arrow_right::before { content: '\e83d'; } /* '' */
.icon-boeuf::before { content: '\e83e'; } /* '' */
.icon-cacaouette::before { content: '\e83f'; } /* '' */
.icon-card_giftcard::before { content: '\e840'; } /* '' */
.icon-carotte::before { content: '\e841'; } /* '' */
.icon-check_circle-empty::before { content: '\e842'; } /* '' */
.icon-check_circle::before { content: '\e843'; } /* '' */
.icon-check-full::before { content: '\e844'; } /* '' */
.icon-checkempty::before { content: '\e845'; } /* '' */
.icon-check::before { content: '\e846'; } /* '' */
.icon-atoms-ic-power::before { content: '\e847'; } /* '' */
.icon-csv::before { content: '\e849'; } /* '' */
.icon-distance::before { content: '\e84a'; } /* '' */
.icon-edit::before { content: '\e84b'; } /* '' */
.icon-epice::before { content: '\e84c'; } /* '' */
.icon-euro::before { content: '\e84d'; } /* '' */
.icon-expand_less::before { content: '\e84e'; } /* '' */
.icon-expand_more::before { content: '\e84f'; } /* '' */
.icon-face::before { content: '\e850'; } /* '' */
.icon-file_download::before { content: '\e851'; } /* '' */
.icon-frigo::before { content: '\e852'; } /* '' */
.icon-garbage::before { content: '\e853'; } /* '' */
.icon-gluten::before { content: '\e854'; } /* '' */
.icon-grade-border::before { content: '\e855'; } /* '' */
.icon-grade::before { content: '\e856'; } /* '' */
.icon-help_outline::before { content: '\e857'; } /* '' */
.icon-infos::before { content: '\e858'; } /* '' */
.icon-laitage::before { content: '\e859'; } /* '' */
.icon-left::before { content: '\e85a'; } /* '' */
.icon-list::before { content: '\e85b'; } /* '' */
.icon-live_help::before { content: '\e85c'; } /* '' */
.icon-lock::before { content: '\e85d'; } /* '' */
.icon-mail_outline::before { content: '\e85e'; } /* '' */
.icon-minus::before { content: '\e85f'; } /* '' */
.icon-more::before { content: '\e860'; } /* '' */
.icon-more2::before { content: '\e861'; } /* '' */
.icon-noisette::before { content: '\e862'; } /* '' */
.icon-notifications::before { content: '\e863'; } /* '' */
.icon-oeuf::before { content: '\e864'; } /* '' */
.icon-ouvert::before { content: '\e865'; } /* '' */
.icon-pdf::before { content: '\e866'; } /* '' */
.icon-perm_phone_msg::before { content: '\e867'; } /* '' */
.icon-phonelink_ring::before { content: '\e868'; } /* '' */
.icon-photo_camera::before { content: '\e869'; } /* '' */
.icon-pin::before { content: '\e86a'; } /* '' */
.icon-plat-chaud::before { content: '\e86b'; } /* '' */
.icon-plat-froid::before { content: '\e86c'; } /* '' */
.icon-poisson::before { content: '\e86d'; } /* '' */
.icon-porc::before { content: '\e86e'; } /* '' */
.icon-price::before { content: '\e86f'; } /* '' */
.icon-print::before { content: '\e870'; } /* '' */
.icon-profil::before { content: '\e871'; } /* '' */
.icon-profil2::before { content: '\e872'; } /* '' */
.icon-right::before { content: '\e873'; } /* '' */
.icon-sales::before { content: '\e874'; } /* '' */
.icon-settings::before { content: '\e875'; } /* '' */
.icon-spotties::before { content: '\e876'; } /* '' */
.icon-sucre::before { content: '\e877'; } /* '' */
.icon-menu::before { content: '\f0c9'; } /* '' */

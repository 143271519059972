@import '~@assets/styles/variables';

.Layout {
  @media (min-width: $screen-md) {
    // tablet
    display: flex;
  }
}

.header {
  position: relative;
  background: $blue-sodexo;
  color: #fff;

  @media (min-width: $screen-md) {
    // tablet
    display: block;
    width: 90px;
    min-height: 100vh;
  }

  @media (min-width: $screen-lg) {
    // desktop
    width: 168px;
  }
}

.content {
  flex: 1;
}

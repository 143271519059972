@import '~@assets/styles/variables';

.OpenCloseSign {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 15px  20px;
  background: rgba($grey-light, 0.1);

  @media (min-width: $screen-md) {
    // tablet
    position: relative;
    padding: 20px  10px;
    font-size: 0.9em;
    text-align: center;
  }

  @media (min-width: $screen-lg) {
    // desktop
    padding: 30px  20px;
    font-size: 1em;
    text-align: left;
  }
}

.openclose {
  display: flex;
  align-items: center;
  font-family: $font-bold;
  font-size: 0.7em;
  text-transform: uppercase;

  &::before {
    $size:10px;

    display: block;
    width: $size;
    height: $size;
    margin-right: 5px;
    background: $warning;
    border-radius: 50%;
    content: ' ';
  }

  &.open {
    &::before { background: $green; }
  }
}

.next {
  margin-top: 10px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $screen-lg) {
    // desktop
    justify-content: space-between;
  }
}

@import '~@assets/styles/variables';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form {
  h3 {
    margin: 30px 0 5px 0;
    color: $blue-sodexo;
  }

  .info {
    color: $grey-darker;
  }
}

.group {
  $spacing:20px;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin: 0 (-$spacing) 0 (-$spacing);

  .field {
    min-width: 200px;
    flex: 1;
    padding: 0 $spacing;
  }
}

// Field
// ************************************************

.MuiInputBase-root {
  min-height: 45px !important;
  padding: 0 20px !important;
  background: $blue-lighter !important;
  //font-family: $font-regular !important;
}

.MuiInputBase-multiline {
  padding: 10px 20px !important;
}

.MuiFormLabel-root {
  color: $blue-sodexo !important;
  font-family: $font-semibold !important;
  font-size: 0.9em !important;
  text-align: left;
}

.MuiInputLabel-shrink {
  font-size: 0.9em !important;
  transform: scale(1) !important;
}

.MuiInput-underline {
  &::before,
  &::after,
  &:hover::before {
    border-bottom: 1px solid $blue-light !important;
  }
}

.MuiInput-input {
  color: $blue-sodexo !important;
  font-family: $font-regular !important;
  font-size: 1em !important;
}

// Password
// ************************************************

.passwordFieldWrapper {
  position: relative;
}

.togglePass {
  position: absolute;
  z-index: 1;
  top: 41px;
  right: 15px;
  color: $grey-dark;
  cursor: pointer;
  font-size: 1.5em;
}

// Field Error
// ************************************************

.fieldError {
  .MuiInput-underline {
    &::before,
    &::after,
    &:hover::before {
      border-bottom: 1px solid $warning !important;
    }
  }
}

.error {
  color: $warning !important;
  font-family: $font-bold;
  font-size: 0.9em;
  text-align: left;

  &.center {
    text-align: center;
  }
}

// Submit
// ************************************************

.formSubmitWrapper {
  margin-top: 2em;
  margin-bottom: 10px;
  text-align: right;

  button {
    min-width: 150px;
  }
}

// Switch
// ************************************************

.switchWrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-family: $font-semibold !important;
  font-size: 0.9em;
}

.MuiSwitch-root {
  width: 50px !important;
  height: 26px !important;
  padding: 0 !important;
  margin-right: 10px;
}

.MuiSwitch-thumb {
  width: 24px !important;
  height: 24px !important;
  box-shadow: none !important;
}

.MuiSwitch-track {
  background: $grey-dark !important;
  border-radius: 13px !important;
  opacity: 1 !important;
}

.MuiSwitch-switchBase {
  padding: 1px !important;

  &.Mui-checked {
    color: #fff;
    transform: translateX(24px) !important;
    // '& + $track': {
    //   backgroundColor: '#52d869',
    //   opacity: 1,
    //   border: 'none',
    // }
    & + .MuiSwitch-track {
      background: $primary !important;
    }

    .MuiSwitch-thumb {
      background: #fff !important;
    }
  }
}

// Checkbox
// ************************************************

.MuiCheckbox-root {
  padding: 0 !important;
}

.checkbox {
  $size:24px;

  display: flex;
  width: $size;
  height: $size;
  align-items: center;
  justify-content: center;
  color: $grey-dark;
  font-size: 30px;

  &.checked {
    background: $primary;
    color: $blue-sodexo;
    font-size: 24px;
  }
}

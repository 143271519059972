@import '~@assets/styles/variables';

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $font-regular;
  font-size: 0.8em;

  &.totalTTC {
    margin-top: 10px;
    font-family: $font-semibold;
    font-size: 1em;
  }
}

@import '~@assets/styles/variables';

.SimpleLoader {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullPage {
    position: absolute;
    width: 100%;
    height: 90vh;
    //box-sizing: border-box;
  }
}

.loader {
  $size:50px;

  // width: $size;
  // height: $size;
  // background: $green;
  // border-radius: 50%;

  img {
    width: $size;
  }
}

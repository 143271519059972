@import '~@assets/styles/variables';

button.LogoutBtn {
  display: flex;
  align-items: center;
  padding-left: 20px !important;
  border: none;
  background: none;
  color: #fff;
  font-size: inherit;

  @media (min-width: $screen-md) {
    padding: 0;
    // tablet
    color: $blue-sodexo;

    i {
      padding-right: 10px;
      font-size: 1.6em;
    }
  }
}

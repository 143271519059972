@import '~@assets/styles/variables';

li.Item {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
}

.product {
  overflow: hidden;
  width: 0;
  flex: 1;
}

.qty {
  $size:20px;

  min-width: $size;
  height: $size;
  margin-right: 3px;
  background: $green;
  color: $blue-sodexo;
  font-family: $font-bold;
  font-size: 13px;
  line-height: $size;
  text-align: center;

  &.nobkg {
    background: none;
  }

  @media (min-width: $screen-md) {
    // tablet
    $size:22px;

    min-width: $size;
    height: $size;
    font-family: $font-bold;
    font-size: 15px;
    line-height: $size;
  }

  @media (min-width: $screen-lg) {
    // desktop
  }
}

.productTitle {
  overflow: hidden;
  flex: 1;
  // font-size: 1.2em;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.specificInformationWrapper {
  margin-bottom: 20px;
}

.specificInformation {
  color: $orange-sodexo;
}

.print {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .specificInformationWrapper {
    margin-bottom: 0;
  }

  .qty {
    margin-right: 8px;
    background: none;
    font-family: $font-bold;
  }

  .productTitle {
    font-family: $font-bold;
    text-transform: uppercase;
  }

  h3 {
    margin: 0;
    margin-top: 10px;
    font-size: inherit;
  }

  * {
    color: #000;
  }
}

@import '~@assets/styles/variables';

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .elmt {
    flex: 1;
  }
  @media (min-width: $screen-md) {
    // tablet
    position: sticky;
    top: 0;
    display: block;
    padding: 0;
    height: 100vh;
    .version {
      position: absolute;
      bottom: 20px;
    }
  }
}
.versiontext {
  font-size: 8px;
  color: #fff;
  text-align: center;
  width: 100%;
}
.burgerBtn {
  padding: 0;
  padding: 0.2em 0;
  border: none;
  background: none;
  color: #fff;
  font-size: 30px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;

  img {
    width: 51px;
  }
}

.drawerPaper {
  background: $blue-sodexo !important;
}

.mobileNavWrapper {
  display: flex;
  min-width: 70vw;
  flex: 1;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 20px 0;
}

.closeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #fff;
  font-size: 1.5em;
  flex-direction: column;
  .version {
    position: absolute;
    bottom: 10px;
  }
}

.close {
  color: #fff;
}

@import '~@assets/styles/variables';

.Offline {
  display: flex;
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  h1 {
    margin-top: 30px;
    margin-bottom: 0;
    color: $grey-dark;
    font-size: 3em;
    text-transform: uppercase;
  }

  p {
    max-width: 600px;
    padding: 20px;
    font-family: $font-bold;
    font-size: 1em;
    text-align: center;
  }

  @media (min-width: $screen-md) {
    h1 {
      font-size: 7em;
    }

    p {
      font-size: 1.2em;
    }
  }
}

.logo {
  width: 100px;
  transform: rotate(180deg);

  @media (min-width: $screen-md) {
    width: 150px;
  }
}

.button {
  margin-top: 20px;
}

@import '~@assets/styles/variables';

.autoselectWrapper {
  .autoselect {
    &__control {
      width: 100%;
      min-height: 45px;
      //padding: 10px 0;
      border: none;
      border-bottom: 1px solid $blue-light;
      background: $blue-lighter;
      border-radius: 0;
      box-shadow: none;
      font-family: $font-regular;

      &:hover {
        border-color: $blue-sodexo;
      }
    }

    &__value-container {
      padding-left: 15px;
    }

    &__single-value,
    &__placeholder {
      overflow: hidden;
      margin: 0;
      color: #9fa0b5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__single-value {
      color: $blue-sodexo;
    }

    &__multi-value {
      background: $primary;
      border-radius: 3px;
      color: $blue-sodexo;

      &__label {
        font-size: 1em;
        transform: translateY(-1px);
      }

      &__remove {
        svg {
          transform: translateY(1px);
        }
      }
    }

    &__dropdown-indicator {
      margin-right: 5px;
      color: $blue-sodexo;
    }

    &__indicator-separator {
      display: none;
    }

    &__option {
      position: relative;
      padding: 8px 12px;
      background: #fff;
      cursor: pointer;

      i.button--right {
        position: absolute;
        top: calc(50% - 0.5em);
        right: 10px;
        color: $grey;
        font-size: 0.5em;
      }

      &:hover,
      &--is-selected {
        background: $blue-sodexo;
        color: #fff;

        i {
          color: #fff;
        }
      }
    }

    &__menu-list {
      padding: 10px 0;
    }
  }
}

.autoselect__menu {
  z-index: 3;
  border: none !important;
  margin: 10px 0 !important;
  border-radius: $border-radius;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  text-align: left;
}

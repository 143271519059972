@import '~@assets/styles/variables';

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  h3 {
    margin: 0;
    color: $grey-dark;
    font-family: $font-black;
    font-size: 10px;
    text-transform: uppercase;
  }

  &.inPreparation, &.acceptedBySupplier {
    background: $blue-sodexo;
    color: #fff;

    &:hover {
      background: darken($blue-sodexo, 5%);
    }
  }

  &.wait4SupplierApproval {
    background: $blue-sodexo;
    color: #fff;

    &:hover {
      background: darken($blue-sodexo, 5%);
    }
  }

  &.wait4Collect {
    background: $brown;

    &:hover {
      background: darken($brown, 5%);
    }
  }

  &.collected {
    background: $grey-dark;

    &:hover {
      background: darken($grey-dark, 5%);
    }

    h3 {
      color: $grey-light;
    }
  }

  @media (min-width: $screen-md) {
    // tablet
    font-size: 1.2em;
  }

  @media (min-width: $screen-lg) {
    // desktop
    padding: 12px 18px;
  }
}

.info {
  font-size: 0.8em;
  white-space: nowrap;

  @media (min-width: $screen-md) {
    // tablet
    font-size: 1em;
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .info {
    margin: 0 10px;

    @media (min-width: $screen-md) {
      // tablet
      margin: 0 10px;
    }

    @media (min-width: $screen-lg) {
      // desktop
      margin: 0 20px;
    }
  }
}

.hasBeenRead {
  $size:11px;

  display: flex;
  width: $size;
  height: $size;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.no {
    background: $green;
  }

  @media (min-width: $screen-md) {
    // tablet
    margin-right: 10px;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .info {
    margin: 0 10px;

    @media (min-width: $screen-md) {
      // tablet
      margin: 0 10px;
    }

    @media (min-width: $screen-lg) {
      // desktop
      margin: 0 10px;
    }
  }
}

.iconbox {
  $size:28px;

  display: flex;
  width: $size;
  height: $size;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1.2em;

  .printBtn {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  @media (min-width: $screen-md) {
    // tablet
    $size:33px;

    width: $size;
    height: $size;
  }

  @media (min-width: $screen-lg) {
    // desktop
  }
}

.print {
  @extend .iconbox;

  position: relative;

  &.wait4Collect,
  &.collected {
    border-color: $blue-sodexo;
    color: $blue-sodexo;

    .printBtn {
      color: $blue-sodexo;

      &:hover {
        color: $blue-sodexo;
      }
    }
  }

  &.info {
    margin-right: 10px;

    @media (min-width: $screen-md) {
      // tablet
      margin-right: 40px;
    }
  }

  .checked {
    $size:18px;

    position: absolute;
    top: -$size/3;
    right: -$size/3;
    display: flex;
    width: $size;
    height: $size;
    align-items: center;
    justify-content: center;
    background: $success;
    border-radius: 50%;
    color: $blue-sodexo;
    font-size: 20px;

    @media (min-width: $screen-md) {
      // tablet
      $size:22px;

      top: -$size/3;
      right: -$size/3;
      width: $size;
      height: $size;
      font-size: 24px;
    }

    @media (min-width: $screen-lg) {
      // desktop
    }
  }
}

.isWarning > * {
  color: $orange-sodexo !important;
}

.warning {
  @extend .iconbox;

  border: 1px solid $orange-sodexo;
  color: $orange-sodexo;
}

@import '~@assets/styles/variables';

.react-datepicker-wrapper {
  width: 100%;

  input {
    border: none;
    border-bottom: 1px solid $blue-light;
    color: $blue-sodexo;
    font-family: $font-regular;
    font-size: 1em;
    outline: none;
  }
}

.react-datepicker-popper {
  color: $blue-sodexo !important;
  font-family: $font-regular !important;
  font-size: 1em !important;

  .react-datepicker {
    border: 1px solid lighten($grey, 5%);
    box-shadow: 0 0 10px #ddd;
  }
}

.react-datepicker__time-list-item {
  height: auto !important;
  padding: 10px 0 !important;

  &--selected {
    background: $primary !important;
    color: $blue-sodexo !important;
  }

  &:hover:not(&--selected) {
    background: $blue-lighter !important;
  }
}

.react-datepicker__header {
  border-bottom: 1px solid lighten($grey, 5%) !important;
  background: $grey-light !important;
  color: $blue-sodexo !important;
}

.react-datepicker__time-container {
  border-left: 1px solid lighten($grey, 5%) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: $blue-sodexo !important;
}

[class*='react-datepicker'] {
  outline: none !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: $primary !important;
  color: $blue-sodexo !important;

  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  &:focus {
    border: none !important;
    outline: none !important;
  }

  background: $primary !important;
  //color: $blue-sodexo !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $blue-sodexo !important;
}

.react-datepicker-time__header {
  color: $grey-light !important;
  //visibility: hidden;

  &::before {
    position: absolute;
    display: block;
    width: 75px;
    color: $blue-sodexo;
    content: 'Heure';
    text-align: center;
  }
}

.react-datepicker__day--disabled {
  &:focus {
    border: none !important;
    outline: none !important;
  }

  color: $blue-light !important;
}

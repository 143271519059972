@import '~@assets/styles/variables';
@import '~@assets/styles/fonts';
@import '~@assets/styles/icons';
@import '~@assets/styles/ui/form';
@import '~@assets/styles/ui/tabs';
@import '~@assets/styles/ui/snackbar';
@import '~@assets/styles/ui/dialog';
@import '~@assets/styles/ui/select';
@import '~@assets/styles/ui/menu';
@import '~@assets/styles/ui/datepicker';
@import '~@assets/styles/pages/carte';

* {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

body {
  padding: 0;
  margin: 0;
  background: $blue-lighter;
  color: $blue-sodexo;
  font-family: $font-regular;
  font-size: 15px;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  user-select: none;

  @media (min-width: $screen-lg) {
    font-size: 15px;
  }
}

h1,
h2 {
  margin-top: 0;
  color: $blue-sodexo;
  font-family: $font-bold;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.2em;
}

p {
  line-height: 1.7em;

  &:first-child {
    margin-top: 0;
  }
}

a {
  border: none;
  color: $dark;
  text-decoration: none;

  &:hover,
  &.active {
    color: $primary;
  }

  &.silent {
    color: inherit;
    text-decoration: inherit;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }

  &.grey {
    color: $grey-dark;

    &:hover {
      color: $primary;
    }
  }
}

button {
  cursor: pointer;
  outline: none;

  &.reset {
    padding: 0;
    border: none;
    background: none;
    //color: inherit;
    font-family: $font-regular;
    font-size: inherit;

    &:hover,
    &.active {
      color: $primary;
    }
  }

  .icon--right {
    margin-left: 10px;

    @media (min-width: $screen-md) {
      // tablet
      margin-left: 15px;
      font-size: 1.2em;
    }

    @media (min-width: $screen-lg) {
      // desktop
    }
  }
}

ul {
  padding: 0;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

section {
  position: relative;
  padding: 15px;

  @media (min-width: $screen-md) {
    // tablet
    padding: 20px;
  }

  @media (min-width: $screen-lg) {
    // desktop
    padding: 30px 56px;
  }
}

strong {
  font-family: $font-bold;
}

iframe {
  border: none;

  &.fb {
    height: 20px;
  }
}

/* ---------------------------------------------
  Blocks
--------------------------------------------- */

.sticky {
  position: sticky;
  top: 0;
}

.paper {
  flex: 1;
  padding: 15px;
  background: #fff;
  box-shadow: 0 3px 30px 0 #e1e6f3;

  h2 {
    font-size: 1.6em;
  }

  @media (min-width: $screen-md) {
    // tablet
    padding: 36px;

    h2 {
      font-size: 2em;
    }
  }

  @media (min-width: $screen-lg) {
    // desktop
  }
}

.flex {
  display: flex;
  // align-items: center;
  // justify-content: center;
}

.flexEllipsis {
  overflow: hidden;
  min-width: 0;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ---------------------------------------------
  spacing
--------------------------------------------- */
@mixin spacing {
  @for $j from 0 through 20 {
    $i : $j*5;

    .pt#{$i} {
      padding-top: #{$i}px !important;
    }

    .pb#{$i} {
      padding-bottom: #{$i}px !important;
    }

    .pl#{$i} {
      padding-left: #{$i}px !important;
    }

    .pr#{$i} {
      padding-right: #{$i}px !important;
    }

    .mt#{$i} {
      margin-top: #{$i}px !important;
    }

    .mb#{$i} {
      margin-bottom: #{$i}px !important;
    }

    .ml#{$i} {
      margin-left: #{$i}px !important;
    }

    .mr#{$i} {
      margin-right: #{$i}px !important;
    }
  }
}

@include spacing;

/* ---------------------------------------------
  Buttons
--------------------------------------------- */

.cta,
a.cta,
button.cta {
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 12px;
  border: 2px solid $primary;
  background: $primary;
  border-radius: 5px;
  color: $blue-sodexo;
  cursor: pointer;
  font-family: $font-semibold;
  font-size: 1em;
  outline: none;
  text-align: center;
  text-decoration: none !important;
  transform: scale(1);
  transition: background 0.3s, border 0.3s, color 0.2s;

  &:hover {
    border: 2px solid darken($primary, 10%);
    background: darken($primary, 10%);
  }

  &--grey {
    border: 2px solid $grey-light;
    background: $grey-light;
    color: $blue-sodexo;
  }

  &--transparent {
    border: 2px solid $blue-sodexo;
    background: $white;
    color: $blue-sodexo;
    &:hover {
      border: 2px solid darken($white, 10%);
      background: darken($white, 10%);
    }
  }

  &--red {
    border: 2px solid $red;
    background: $red;
    color: $blue-sodexo;
    &:hover {
      border: 2px solid darken($red, 10%);
      background: darken($red, 10%);
    }
  }

  &--small {
    padding: 5px 8px;
    font-size: 0.8em;
  }

  &--auto {
    height: auto;
    flex: 1;
    padding: 1.5em;
    line-height: 2em;
  }

  &--big {
    border: 2px solid $secondary;
    background: $secondary;
    text-transform: uppercase;
  }

  &--fullWidth {
    width: 100%;
    box-sizing: border-box;

    &:hover {
      transform: scale(1);
    }
  }

  &--ttu {
    font-size: 0.9em;
    text-transform: uppercase;
  }

  &--disabled {
    border: 2px solid $grey-dark;
    background: $grey-dark;
    color: #fff;
    cursor: default;
    pointer-events: none;

    &:hover {
      border: 2px solid $grey-dark;
      background: $grey-dark;
      transform: scale(1);
    }
  }

  @media (min-width: $screen-md) {
    // tablet
    padding: 13px 20px;
  }

  @media (min-width: $screen-lg) {
    // desktop
  }
}

/* ---------------------------------------------
  Text
--------------------------------------------- */

.underline {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 5px solid #fff;
}

.ttu {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.error {
  color: $red;
}

.success {
  color: $green;
}

.primary {
  color: $primary;
}

.info {
  color: $grey;
}

/* ---------------------------------------------
  Anims
--------------------------------------------- */
.spin {
  display: inline-block;
  animation: spin 0.5s infinite linear;
  //transform: rotate(450deg);
  &--right {
    margin-left: 10px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

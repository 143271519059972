@import '~@assets/styles/variables';

.DetailCommande {
  h3 {
    margin-bottom: 5px;
    color: $grey-dark;
    font-family: $font-black;
    font-size: 10px;
    text-transform: uppercase;
  }

  display: flex;
  overflow: hidden;
  justify-content: space-between;
  padding: 0 10px;
  padding-right: 0;
  background: #fff;

  @media (min-width: $screen-md) {
    // tablet
    padding: 0 13px;
  }

  @media (min-width: $screen-lg) {
    // desktop
    padding: 0 16px;
  }
}

// ITEMS
//*************************

.items {
  flex: 1.8;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 0.8em;

  @media (min-width: $screen-md) {
    // tablet
    font-size: 1em;
  }

  @media (min-width: $screen-lg) {
    // desktop
    flex: 2;
  }
}


$blue-sodexo: #222157;
$blue-light:#e1e6f3;
$blue-lighter: #f4f5f8;
$orange-sodexo: #ff9673;
$yellow:#ffe968;
$green:#7eefb6;
$brown:#eee0d0;
$red: #f46060;
$white:#ffff;

$grey-light: #f4f5f8;
$grey: #bbb;
$grey-dark: #a3a9b5;
$grey-darker:#a8aeb9;
$dark: #000;

$primary: $green;
$secondary: $blue-sodexo;

$success: $green;
$warning: $orange-sodexo;
$error: $red;
$info: $blue-sodexo;

$screen-sm: 568px; // and above : large mobile
$screen-md: 760px; // and above : tablet
$screen-lg: 1200px; // and above : desktop
$screen-xl: 1600px; // and above : large desktop

$border-radius: 5px;

$font-regular:'CircularStd-Book', arial, helvetica, sans-serif;
$font-semibold:'CircularStd-Medium', arial, helvetica, sans-serif;
$font-black:'CircularStd-Black', arial, helvetica, sans-serif;
$font-bold:'CircularStd-Bold', arial, helvetica, sans-serif;

@import '~@assets/styles/variables';

$tabHeight: 40px;

.MuiTabs-root {
  min-height: $tabHeight !important;
}

.MuiTab-root {
  min-height: $tabHeight !important;
  // width: 150px !important;
  // min-width: 150px !important;
  padding: 0 !important;
  margin-right: 49px !important;
  text-transform: inherit !important;
}

.MuiTab-wrapper {
  display: inline !important;
  font-family: $font-bold;
  text-align: left;
}

.MuiTab-textColorSecondary {
  color: $grey-dark !important;

  &.Mui-selected {
    color: $blue-sodexo !important;
  }
}

[class*=PrivateTabIndicator] {
  height: 5px !important;
}

@import '~@assets/styles/variables';

.OrderDetailAside {
  $br:10px;

  flex: 1.2;
  padding: 10px;
  background: $grey-light;
  border-radius: 0 0 $br $br;
  font-family: $font-semibold;
  font-size: 0.8em;

  h3 {
    margin-top: 20px;

    &:first-child {
      margin-top: 10px;
    }
  }

  @media (min-width: $screen-md) {
    // tablet
    font-size: 1em;
  }

  @media (min-width: $screen-lg) {
    // desktop
    flex: 1;
    padding: 10px 15px;
    padding-bottom: 15px;
  }
}

.couverts {
  display: flex;
  align-items: center;

  i {
    font-size: 1.5em;
  }

  .check {
    $size:0.9em;

    display: flex;
    width: $size;
    height: $size;
    align-items: center;
    justify-content: center;

    &.active {
      background: $green;
    }
  }
}

@import '~@assets/styles/variables';

.MuiMenuItem-root {
  font-family: $font-regular !important;
  font-size: 1em !important;
  transition: none !important;

  &:hover {
    background: $blue-sodexo !important;
    color: #fff !important;
  }
}

.MuiIconButton-root {
  &:hover {
    background: none !important;
  }
}

@font-face {
  font-family: 'CircularStd-MediumItalic';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/CircularStd/CircularStd-MediumItalic.eot');
  src:
    local('Circular Std Medium Italic'),
    local('CircularStd-MediumItalic'),
    url('../fonts/CircularStd/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-MediumItalic.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-MediumItalic.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-Black';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/CircularStd/CircularStd-Black.eot');
  src:
    local('Circular Std Black'),
    local('CircularStd-Black'),
    url('../fonts/CircularStd/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-Black.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-Black.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/CircularStd/CircularStd-Medium.eot');
  src:
    local('Circular Std Medium'),
    local('CircularStd-Medium'),
    url('../fonts/CircularStd/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-Medium.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-Medium.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/CircularStd/CircularStd-Bold.eot');
  src:
    local('Circular Std Bold'),
    local('CircularStd-Bold'),
    url('../fonts/CircularStd/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-Bold.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-Bold.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-BlackItalic';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/CircularStd/CircularStd-BlackItalic.eot');
  src:
    local('Circular Std Black Italic'),
    local('CircularStd-BlackItalic'),
    url('../fonts/CircularStd/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-BlackItalic.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-BlackItalic.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-BookItalic';
  font-style: italic;
  font-weight: normal;
  src: url('../fonts/CircularStd/CircularStd-BookItalic.eot');
  src:
    local('Circular Std Book Italic'),
    local('CircularStd-BookItalic'),
    url('../fonts/CircularStd/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-BookItalic.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-BookItalic.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-BookItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-BoldItalic';
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/CircularStd/CircularStd-BoldItalic.eot');
  src:
    local('Circular Std Bold Italic'),
    local('CircularStd-BoldItalic'),
    url('../fonts/CircularStd/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-BoldItalic.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-BoldItalic.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-Book';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/CircularStd/CircularStd-Book.eot');
  src:
    local('Circular Std Book'),
    local('CircularStd-Book'),
    url('../fonts/CircularStd/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd/CircularStd-Book.woff2') format('woff2'),
    url('../fonts/CircularStd/CircularStd-Book.woff') format('woff'),
    url('../fonts/CircularStd/CircularStd-Book.ttf') format('truetype');
}

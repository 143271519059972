@import '~@assets/styles/variables';

.SubHeader {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $blue-light;
  background: #fff;
}

.box {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-left: 2px solid $blue-light;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifs {
  padding-right: 65px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  i {
    position: relative;
    font-size: 1.8em;

    &::after {
      position: absolute;
      top: -2px;
      left: 100%;
      display: block;
      padding: 0 2px;
      border: 2px solid #fff;
      margin-left: -14px;
      background: $warning;
      border-radius: 2px;
      content: 'Désactivé';
      font-family: $font-regular;
      font-size: 12px;
      font-style: normal;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  &.active {
    padding-right: 45px;

    i {
      &::after {
        background: $primary;
        content: 'Activé';
      }
    }
  }
}

.btnNotif {
  &:hover {
    color: inherit;
  }
}

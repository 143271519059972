//handles carte & stocks items list

@import '~@assets/styles/variables';

.CarteGroupItems {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0;
  padding-right: 0;
  padding-left: 0;

  //margin-bottom: 20px;

  &.products {
    padding: 30px 44px;
    border-top: 2px solid $blue-sodexo;
  }

  @media (min-width: $screen-md) {

  }

  @media (min-width: $screen-lg) {
    // desktop
    padding-left: 15px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .info {
    margin-bottom: 20px;
    color: $grey-dark;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }

  .itemSpacer {
    display: flex;
    min-width: 300px;
    flex: 1;

    @media (min-width: $screen-md) {
      // tablet
      min-width: 100%;
      max-width: 100%;
    }

    @media (min-width: $screen-lg) {
      // desktop
      min-width: 50%;
      max-width: 50%;
    }
  }

  .itemWrapper {
    min-width: 0;
    box-sizing: border-box !important;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    border: 1px solid $blue-light;
    margin: 10px;
    background: #fff;
    border-radius: 5px;
    color: $blue-sodexo;
    font-family: $font-regular;
    font-size: 1em;
    text-align: left;

    [class*='MuiSwitch-root'] {
      margin-right: 0;
    }

    &--carte {
      &:hover {
        border: 1px solid $primary;
      }
    }
  }

  .item {
    min-width: 0;
    flex: 1;

    &.disabled {
      color: $grey-dark;
    }

    .flexContent {
      display: flex;
      min-width: 0;
      flex: 1;
      align-items: center;
    }

    .image {
      $size:43px;

      display: flex;
      width: $size;
      height: $size;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-position: center;
      background-size: cover;
      font-size: 1.2em;

      &.empty {
        border: 1px dashed $blue-sodexo;
      }
    }

    .content {
      min-width: 0;
      flex: 1;
    }

    .title {
      overflow: hidden;
      min-width: 0;
      flex: 1;
      //margin-bottom: 5px;
      //font-family: $font-bold;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--stock {
        margin-bottom: 5px;
        font-family: $font-bold;
      }
    }

    .unitPrice {
      margin-top: 5px;
      color: $grey-dark;
    }
  }

  .addItemBtn {
    border: 1px dashed $blue-sodexo;
    border-style: dashed;
    background: none;
    background-color: $blue-light;
    color: $blue-sodexo;
    font-family: $font-regular;
    font-size: 1em;

    .flex {
      display: flex;
    }

    i {
      $size:22px;

      display: inline-flex;
      width: $size;
      height: $size;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background: $primary;
      border-radius: 50%;
      color: $blue-sodexo;
      font-size: 1.1em;
    }

    &:hover {
      border-color: $blue-light;
    }
  }
}

.deleteItemIcon {
  color: $warning;
  text-align: left;
  i {
    padding-right: 10px;
  }
  &:hover{
    cursor: pointer;
  }
}

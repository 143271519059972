
@import '~@assets/styles/variables';

.snack {
  $height:70px;

  display: flex;
  width: 472px;
  height: $height;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  border: 1px solid $primary;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 24px 0 rgba(34, 33, 87, 0.2);

  .left {
    display: flex;
    //width: 50px;
    height: $height;
    align-items: center;
    justify-content: center;
    background: $primary;
    color: $blue-sodexo;

    i { font-size: 3.6em; }
  }

  .message {
    padding: 0 20px;
  }

  button {
    align-self: start;
    margin-top: 10px;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 95%;
  bottom: 10px !important;
  left: 50%;
  transform: translateX(-50%); 

  @media (min-width: $screen-md) {
    // tablet
    padding: 0 13px;
    flex-direction: row;
    margin: 10px 0;
    position: inherit;
    left: inherit;
    transform: inherit; 
  }

  @media (min-width: $screen-lg) {
    // desktop
    padding: 0 16px;
  }
  .accept {
    flex: 2 1;
    width: 100%;
  }

  .refuse, .back {
    flex: 2 1;
    width: 100%;
    margin-bottom: 10px;
    @media (min-width: $screen-md) {
      margin: 0 40px 0 0;
    }
  }
}

.modalRefused {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  h1 {
    font-size: 21px;
    margin-top: 20px;
  }
  .actions {
    button {
      border-radius: 9px;
    }
  }
  .refuse {
    margin-bottom: 0px;
    @media (min-width: $screen-md) {
      margin: 0;
    }
  }
  .back {
    margin-bottom: 10px;
    @media (min-width: $screen-md) {
      margin: 0 80px 0 0;
    }
  }
  .arrow_back{
    position: absolute;
    left: 15px;
  }
  .borderChoice {
    border-bottom: 1px solid #edeef1;
    border-top: 1px solid #edeef1;
  }
}

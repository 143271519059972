@import '~@assets/styles/variables';

.nav {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    padding: 0;
    padding-left: 20px;
    margin-bottom: 20px;

    @media (min-width: $screen-md) {
      // tablet
      padding-left: 0;
      margin-bottom: 10px;
    }
  }

  a {
    display: flex;
    height: 35px;
    align-items: center;
    padding-left: 20px;
    border-right: 5px solid $blue-sodexo;
    color: #fff;
    //transition: border 0.3s ease-out;
    font-family: $font-regular;
    font-size: 1em;
    outline: none;
    text-decoration: none;

    &:hover,
    &.active {
      color: $primary;
    }

    &.active {
      border-color: $primary;
      font-family: $font-bold;
    }

    @media (min-width: $screen-sm) {
      // large mobile
    }

    @media (min-width: $screen-md) {
      justify-content: center;
      padding-right: 20px;

      i {
        padding: 0;
      }
    }

    @media (min-width: $screen-lg) {
      justify-content: flex-start;
      // desktop
      i {
        padding-right: 10px;
      }
    }

    @media (min-width: $screen-xl) {
      // large desktop
    }
  }

  i {
    padding-right: 10px;
    font-size: 1.3em;
  }

  .label {
    @media (min-width: $screen-md) {
      // tablet
      display: none;
    }

    @media (min-width: $screen-lg) {
      // desktop
      display: inline-block;
    }
  }

  @media (min-width: $screen-md) {
    // tablet
    margin-top: 30px;
  }
}

.burgerBtn {
  height: 30px;
  padding: 0;
  border: none;
  margin-left: 10px;
  background: none;
  font-size: 30px;
}
